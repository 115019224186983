<template>
  <div>
    <h3 v-if="categoryName" class="category-title">
      {{ categoryName }}
    </h3>

    <b-list-group class="w-100 pointer product-categories-list">
      <div v-if="showCategories">
        <selection-cards
          :items="formattedProductCategories"
          @chosen="handleChosen"
        />
      </div>
      <ct-centered-spinner v-else class="mt-5" />
    </b-list-group>

    <formation-info-modal
      ref="formation-info-modal"
      v-model="companyAdded"
      :company="company"
      :load-products="false"
      @formation-info-modal-complete="formationInfoModalCompleteHandler"
    />
    <company-domestic-registration-modal
      ref="company-domestic-registration-modal"
      :company="company"
      :load-products="false"
      @domestic-registration-changed="domesticRegistrationChanged"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { getProductRoute } from '@/components/HireUs/helper'
  export default {
    name: 'ChooseProductCategory',
    components: {
      CompanyDomesticRegistrationModal: () => import('@/components/CompanyDomesticRegistrationModal'),
      CtCenteredSpinner:                () => import('@/components/shared/CtCenteredSpinner'),
      FormationInfoModal:               () => import('@/components/StagelineV2/modals/FormationInfoModal'),
      SelectionCards:                   () => import('@/components/shared/SelectionCards'),
    },
    props: {
      companyId: String,
    },
    data() {
      return {
        isLoaded: false,
        phoneTrial: false,
        companyAdded: false,
        productKind: '',
      }
    },
    computed: {
      ...mapGetters('products', [
        'productBuckets',
        'productCategories',
        'products',
      ]),
      ...mapGetters('companies', { company: 'currentCompany' }),
      ...mapGetters('companies', [
        'domesticRegistration',
        'noJurisdictionForCompany',
        'formationInfoObtained',
      ]),
      ...mapGetters('jurisdictions', ['jurisdictions']),
      ...mapGetters('checkout', [
        'productsLoaded',
      ]),
      ...mapGetters('monitoring', [
        'monitoringServiceActive',
      ]),
      routeParams() {
        return this.$store.state.appRouter.params
      },
      formattedProductCategories() {
        let formattedList = this.productCategories
        formattedList = this.filterMonitoringCategoriesIfActive(formattedList)

        return formattedList
          .sort((a, b) => a.sorting_order - b.sorting_order)
          .map(category => {
            return {
              bucketId: this.routeParams.bucketId,
              kind: category.kind,
              name: category.name,
              categoryId: category.id,
              hasSubCategories: category.has_sub_categories,
              route: 'choose-product',
              description: category.description,
              bucketName: category.bucket_name,
            }
          })
      },
      showCategories() {
        return this.isLoaded && this.formattedProductCategories.length
      },
      categoryIdBucketId() {
        return `${this.routeParams.bucketId}-${this.routeParams.categoryId}`
      },
      categoryName() {
        return this.routeParams.categoryName ||
          (
            this.formattedProductCategories.length > 0 ?
            this.formattedProductCategories[0]?.bucketName :
            ''
          )
      },
    },
    watch: {
      async categoryIdBucketId() {
        this.isLoaded = false
        await this.getProductCategories(this.routeParams)
        this.isLoaded = true
      },
    },
    async mounted() {
      await this.ensureProductsLoaded()
      this.$emit('toggleButtonVisibility', { backButtonHidden: false, addContactButtonHidden: true })

      if (!this.jurisdictions.length) {
        await this.loadJurisdictions
      }

      await this.getProductCategories(this.routeParams)
      this.$root.$emit('log-interaction', { type: 'view', name: 'view-categories', subName: this.categoryName  })
      this.isLoaded = true
    },
    methods: {
      ...mapActions('products', [
        'getProductCategories',
        'getProducts',
      ]),
      ...mapActions('jurisdictions', { loadJurisdictions: 'load' }),
      ...mapActions('checkout', ['loadProducts']),
      async ensureProductsLoaded() {
        if (!this.productsLoaded) await this.loadProducts(this.companyId)
      },
      makeToast(variant = null, title, body) {
        this.$bvToast.toast(body, {
          title: title,
          variant: variant,
          solid: true,
        })
      },
      async route(items, hasSubCategories) {
        if (await this.verifyRequiredCompanyInfo()) {
          const route = await getProductRoute(items, this.category, this.companyId, hasSubCategories)
          await this.$router.push(route)
        }
      },
      async handleChosen(category=null) {
        if (category) { this.category = category }
        this.$root.$emit('log-interaction', { type: 'button-redirect', name: 'category-selected', subName: category.name  })
        const params = {
          companyId: this.companyId,
          bucketId: this.category.bucketId,
          categoryId: this.category.categoryId,
        }

        this.isLoaded = false
        if (!category.hasSubCategories) {
          await this.getProducts(params)

          // Filter out any bundles that do not have any products available
          const filteredProducts = this.products.filter(p =>
            !(p?.kind === 'product_bundle' && (!p.products || p.products.length === 0))
          )

          if (filteredProducts.length >= 1 ) {
            await this.route(this.products, category.hasSubCategories)
          } else {
            this.makeToast('warning', 'No Products/Categories', `No products/categories to show for category "${category.name}"`)
            this.isLoaded = true
          }
        }
        else {
          await this.getProductCategories(params)
          if (this.productCategories.length >= 1 ) {
            await this.route(this.productCategories, category.hasSubCategories)
          }
          else {
            this.makeToast('warning', 'No Products/Categories', `No products/categories to show for category "${category.name}"`)
            this.isLoaded = true
          }
        }
      },
      // Made async to allow using return value in route check
      async verifyRequiredCompanyInfo() {
        return new Promise((resolve, _reject) => {
          if (!this.formationInfoObtained && [null, undefined].includes(this.company?.config['formed_elsewhere'])) {
            this.presentFormationInfoModal()
            resolve(false)
          } else if (this.noJurisdictionForCompany) {
            this.presentCompanyDomesticRegistrationModal()
            resolve(false)
          } else {
            resolve(true)
          }
        })
      },
      presentFormationInfoModal() {
        this.$refs['formation-info-modal'].show()
      },
      presentCompanyDomesticRegistrationModal() {
        this.$refs['company-domestic-registration-modal'].show()
      },
      async formationInfoModalCompleteHandler() {
        await this.route(this.productKind)
      },
      async domesticRegistrationChanged() {
        await this.route(this.productKind)
      },
      filterMonitoringCategoriesIfActive(formattedList) {
        return formattedList.filter(item => (item.name !== "Monitoring" ||  !this.monitoringServiceActive))
      },
    },
  }
</script>

<style lang="scss" scoped>

.category-title {
  text-align: left;
}

.product-categories-list {
  padding-top: 1rem;
}

</style>
