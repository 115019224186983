var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.categoryName
        ? _c("h3", { staticClass: "category-title" }, [
            _vm._v("\n    " + _vm._s(_vm.categoryName) + "\n  "),
          ])
        : _vm._e(),
      _c(
        "b-list-group",
        { staticClass: "w-100 pointer product-categories-list" },
        [
          _vm.showCategories
            ? _c(
                "div",
                [
                  _c("selection-cards", {
                    attrs: { items: _vm.formattedProductCategories },
                    on: { chosen: _vm.handleChosen },
                  }),
                ],
                1
              )
            : _c("ct-centered-spinner", { staticClass: "mt-5" }),
        ],
        1
      ),
      _c("formation-info-modal", {
        ref: "formation-info-modal",
        attrs: { company: _vm.company, "load-products": false },
        on: {
          "formation-info-modal-complete":
            _vm.formationInfoModalCompleteHandler,
        },
        model: {
          value: _vm.companyAdded,
          callback: function ($$v) {
            _vm.companyAdded = $$v
          },
          expression: "companyAdded",
        },
      }),
      _c("company-domestic-registration-modal", {
        ref: "company-domestic-registration-modal",
        attrs: { company: _vm.company, "load-products": false },
        on: {
          "domestic-registration-changed": _vm.domesticRegistrationChanged,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }